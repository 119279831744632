define("ember-scroll/services/scroll", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "@ember/object/internals"], function (_exports, _service, _object, _runloop, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ScrollService = _exports.default = (_class = class ScrollService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "guid", `${(0, _internals.guidFor)(this)}-scroll-target`);
      _defineProperty(this, "doScroll", true);
      _defineProperty(this, "_hasSetupElement", false);
      if (window.addEventListener) {
        window.addEventListener('popstate', this.handlePopstate);
      }
    }
    handlePopstate() {
      // we want the popstate event to be handled in between routeWillChange and routeDidChange
      (0, _runloop.next)(() => {
        this.doScroll = false;
      });
    }
    routeWillChange() {
      this.doScroll = true;
    }
    routeDidChange(transition) {
      // don't mess with the scroll there is no from in the route transition (meaning its the initial load)
      if (!transition.from) {
        this.doScroll = true;
        return;
      }

      // we want this to happen after the popstate event is handled
      (0, _runloop.next)(() => {
        (0, _runloop.scheduleOnce)('afterRender', this.scrollUp);
      });
    }
    scrollUp() {
      if (this.doScroll) {
        this._setupElement();
        document.getElementById(this.guid).focus();
        window.scrollTo(0, 0);
      }
      this.doScroll = true;
    }
    _setupElement() {
      if (this._hasSetupElement) {
        return;
      }
      const element = document.createElement('div');
      const text = document.createTextNode('The page navigation is complete. You may now navigate the page content as you wish.');
      element.append(text);
      element.setAttribute('id', this.guid);
      element.setAttribute('class', 'ember-scroll-navigation-message');
      element.setAttribute('tabindex', -1);
      element.setAttribute('role', 'text');
      document.body.prepend(element);
      this._hasSetupElement = true;
    }
    willDestroy() {
      if (window.removeEventListener) {
        window.removeEventListener('popstate', this.handlePopstate);
      }
      const element = document.getElementById(this.guid);
      if (element) {
        element.remove();
      }
      super.willDestroy();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handlePopstate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePopstate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "routeDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scrollUp"), _class.prototype), _class);
});